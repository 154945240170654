<template>
    <div class="flex items-center">
        <div class="mr-2">
            <slot name="icon"></slot>
        </div>
        <datetime type="datetime" :value="value" v-on:input="input"
        :placeholder="value"
        :placeholder2="$t('restaurant.choose_time')"
        class="theme-primary" :phrases="{ok: $t('restaurant.okay'), cancel: $t('restaurant.cancel')}"></datetime>
    </div>
</template>

<script>
import Vue from 'vue'
import { Datetime } from 'vue-datetime'
Vue.use(Datetime)

export default {
    name: 'Datepicker',
    props: ['value'],
    methods: {
        input (val) {
            if (val && val !== '') {
                this.$emit('input', val)
            }
        }
    },
    created () {
        // setInterval(() => console.log(this.value), 1000)
    }
}
</script>

<style>
    .vdatetime.theme-primary input {
        @apply focus:ring-primary focus:border-primary block w-full p-3.5 border-gray-200 rounded-md;
    }
</style>
