<template>
    <modal @close="close">
        <template #title>{{ $t('dashboard.add_additiv') }}</template>
        <template #interactive><button-b :loading="loading" @click.native="saveAndClose" class="bg-primary">{{ $t('dashboard.add') }}</button-b></template>
        <template #body>
            <div class="grid grid-cols-6 gap-6">
                <!--
                <div class="col-span-6">
                    <label for="name" class="block text-sm font-bold mb-1">{{ $t('dashboard.status') }}</label>
                    <multiselect
                    v-model="valueStatus"
                    :options="[ 'Aktiv', 'Inaktiv' ]"
                    :placeholder="$t('dashboard.choose_status')"
                    :deselectLabel="$t('dashboard.remove')"
                    :selectedLabel="$t('dashboard.selected')"
                    :selectLabel="$t('dashboard.select')">
                    <template slot="noResult">{{ $t('dashboard.no_results') }}</template>
                    </multiselect>
                </div>
                -->
                <div class="col-span-6">
                    <input-normal v-model="title" inputtype="text" inputname="name" inputid="name" :inputplaceholder="$t('dashboard.example')">
                        <template #title>{{ $t('dashboard.title') }}</template>
                    </input-normal>
                </div>
                <div class="col-span-6">
                    <input-textarea v-model="description" :inputplaceholder="$t('dashboard.example_desc')">
                        <template #title>{{ $t('dashboard.description') }}</template>
                    </input-textarea>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import InputNormal from '../../components/ui/InputNormal.vue'
import ButtonB from '../../components/ui/ButtonB.vue'
import Modal from '../../components/dashboard/Modal.vue'
// import Multiselect from 'vue-multiselect'
import InputTextarea from '../ui/InputTextarea.vue'
export default {
    name: 'DashboardSettings',
    data () {
        return {
            valueStatus: null,
            title: '',
            description: '',
            loading: false
        }
    },
    methods: {
        async saveAndClose () {
            this.loading = true
            this.$store.state.catalog.addAdditive({ title: this.title, description: this.description })
            await this.$store.state.catalog.commit()
            this.loading = false
            this.close()
        },
        close () {
            this.$emit('close')
        }
    },
    components: {
        InputNormal,
        ButtonB,
        Modal,
        InputTextarea
    }
}
</script>

<style src="@/assets/css/multiselect.css"></style>
