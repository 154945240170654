<template>
<div class="col-span-6">
    <div >
        <label class="text-sm font-bold mb-1">{{ $t('dashboard.status') }}</label>
        <multiselect
        v-model="state"
        @input="availability = upgrade.availability"
        :options="[ 'ONLINE', 'OFFLINE', 'TIMED' ]"
        :placeholder="$t('dashboard.choose_status')"
        :deselectLabel="$t('dashboard.remove')"
        :selectedLabel="$t('dashboard.selected')"
        :selectLabel="$t('dashboard.select')"
        :multiple="false">
        <template slot="noResult">{{ $t('dashboard.no_results') }}</template>
        <template slot="singleLabel" slot-scope="{ option }"><strong>{{ $t(`dashboard.status_${option}`) }}</strong></template>
        <template slot="option" slot-scope="{ option }">{{ $t(`dashboard.status_${option}`) }}</template>
        </multiselect>
        <transition name="slide-fade">
            <timed-extra v-show="state == 'TIMED'" :availability="availability"/>
        </transition>
    </div>
    <div class="mt-5">
        <label class="text-sm font-bold">{{ $t('dashboard.availability') }}</label>
        <multiselect
        v-model="available"
        @input="availability = upgrade.availability"
        :options="[ 'ALWAYS', 'NEVER', 'TIMED' ]"
        :placeholder="$t('dashboard.choose_availability')"
        :deselectLabel="$t('dashboard.remove')"
        :selectedLabel="$t('dashboard.selected')"
        :selectLabel="$t('dashboard.select')"
        :multiple="false">
        <template slot="noResult">{{ $t('dashboard.no_results') }}</template>
        <template slot="singleLabel" slot-scope="{ option }"><strong>{{ $t(`dashboard.available_${option}`) }}</strong></template>
        <template slot="option" slot-scope="{ option }">{{ $t(`dashboard.available_${option}`) }}</template>
        </multiselect>
        <transition name="slide-fade">
        <extra-timewindow v-show="available == 'TIMED'" :availability="availability"/>
        </transition>
    </div>
    </div>
</template>

<script>
import ExtraTimewindow from '@/components/dashboard/ExtraTimewindow.vue'
import Multiselect from 'vue-multiselect'
import { Availability, Upgrade } from 'delivery-boosting-2021-model'
import TimedExtra from '@/components/dashboard/StatusTimed.vue'

export default {
    components: { ExtraTimewindow, Multiselect, TimedExtra },
    data () {
        return {
            valueAailable: '',
            valueStatus: '',
            i: true
        }
    },
    props: {
        availability: {
            type: Availability
        },
        upgrade: {
            type: Upgrade
        }
    },
    computed: {
        state: {
            get () {
                // eslint-disable-next-line no-unused-expressions
                this.i
                return this.availability._data.state || ''
            },
            set (state) {
                this.availability.state = state
            }
        },
        available: {
            get () {
                // eslint-disable-next-line no-unused-expressions
                this.i
                return this.availability._data.available || ''
            },
            set (available) {
                this.availability.available = available
            }
        }
    },
    created () {
        setInterval(() => { this.i = !this.i }, 100)
    }
}
</script>

<style>
.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .5s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(100px);
  opacity: 0;
}
</style>
