<template>
    <div class="flex items-center mt-2">
        <datepicker v-model="availability.stateTimeFrameFrom">
            <template #icon>
                <icon-calendar/>
            </template>
        </datepicker>
        <span>-</span>
        <datepicker v-model="availability.stateTimeFrameTo"/>
    </div>
</template>

<script>
import Datepicker from '@/components/ui/Datepicker.vue'
import IconCalendar from '@/components/icons/IconCalendar.vue'
import { Availability } from 'delivery-boosting-2021-model'

export default {
    components: {
        Datepicker, IconCalendar
    },
    props: {
        availability: {
            type: Availability
        }
    },
    created () {
        /* setInterval(() => {
            // console.log('st', this.availability.stateTimeFrameFrom)
            // eslint-disable-next-line no-self-assign
            this.availability.stateTimeFrameFrom = '' + this.availability.stateTimeFrameFrom
        }
        , 1000) */
    }
}
</script>
