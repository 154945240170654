<template>
    <div class="flex items-center mt-5">
        <span class="mr-2 mt-1">{{ $t('dashboard.from') }}</span>
        <input-small v-model="from" class="w-28" inputtype="time" inputname="name" inputid="name" inputplaceholder="00:00"/>
        <span class="mx-2 mt-1">-</span>
        <input-small v-model="to" class="w-28" inputtype="time" inputname="name" inputid="name" inputplaceholder="00:00"/>
        <a @click="$emit('remove')" class="ml-2 mt-1 hover:text-primary cursor-pointer"><icon-x class="h-6"/></a>
    </div>
</template>

<script>
import IconX from '@/components/icons/IconX.vue'
import InputSmall from '@/components/ui/InputSmall.vue'

export default {
    components: { IconX, InputSmall },
    props: {
        timeFrame: {
            type: Object
        }
    },
    computed: {
        from: {
            get () {
                var a = this.timeFrame.from[0]
                var b = this.timeFrame.from[1]
                var final = (a + ':' + b)
                return final
            },
            set (val) {
                var times = val.split(':')
                this.timeFrame.from[0] = times[0]
                this.timeFrame.from[1] = times[1]
            }
        },
        to: {
            get () {
                var a = this.timeFrame.to[0]
                var b = this.timeFrame.to[1]
                var final = (a + ':' + b)
                return final
            },
            set (val) {
                var times = val.split(':')
                this.timeFrame.to[0] = times[0]
                this.timeFrame.to[1] = times[1]
            }
        }
    }
}

</script>
